<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
          <div class="text-right">
            <button
              type="button"
              class="mb-2 mr-2 btn btn-primary"
              data-toggle="modal"
              data-target="#filterModal"
            >
              <i class="fa fa-filter"></i>
            </button>
          </div>
          <div class="widget-content widget-content-area br-6">
            <div v-if="loading">
              <b-skeleton-table
                :rows="10"
                :columns="5"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>

            <div v-else class="mt-4 mb-4 table-responsive">
              <table
                id="ONBOARDING_SPONSORS"
                class="table table-hover non-hover"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th>Sponsor</th>
                    <th>Sponsor Phone</th>
                    <th>Sponsor Email</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Registration Completed</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="sponsor in onboardingSponsors" :key="sponsor.id">
                    <td>{{ sponsor.name }}</td>
                    <td>{{ sponsor.phone_number }}</td>
                    <td>{{ sponsor.email }}</td>
                    <td>
                      <label
                        class="new-control new-checkbox new-checkbox-rounded new-checkbox-text checkbox-success"
                      >
                        <input
                          type="checkbox"
                          class="new-control-input"
                          readonly
                          v-model="sponsor.patients_count"
                        />
                        <span class="new-control-indicator"></span>
                        <span
                          class="new-chk-content"
                          v-if="sponsor.patients_count > 0"
                        >
                          Elderly Added</span
                        >
                        <span class="new-chk-content" v-else>No Elderly</span>
                      </label>
                      <label
                        class="new-control new-checkbox new-checkbox-rounded new-checkbox-text checkbox-success"
                      >
                        <input
                          type="checkbox"
                          class="new-control-input"
                          readonly
                          v-model="sponsor.transactions_count"
                        />
                        <span class="new-control-indicator"></span>
                        <span
                          class="new-chk-content"
                          v-if="sponsor.transactions_count > 0"
                          >Subscribed</span
                        >
                        <span class="new-chk-content" v-else
                          >No Subscription</span
                        >
                      </label>
                      <label
                        class="new-control new-checkbox new-checkbox-rounded new-checkbox-text checkbox-danger"
                        v-if="sponsor.is_closed"
                      >
                        <input
                          type="checkbox"
                          class="new-control-input"
                          readonly
                          v-model="sponsor.is_closed"
                        />
                        <span class="new-control-indicator"></span>
                        <span class="new-chk-content" v-if="sponsor.is_closed"
                          ><i class="fa fa-lock"></i
                        ></span>
                      </label>
                    </td>
                    <td>{{ formatDateTime(sponsor.created_at) }}</td>
                    <td>
                      <span v-if="sponsor.user_count">Completed</span>
                      <span v-else>Not Completed</span>
                    </td>
                    <td>
                      <div class="btn-group">
                        <router-link
                          :to="{
                            name: 'Onboarding Sponsor',
                            params: { id: sponsor.id },
                          }"
                          class="btn btn-success btn-sm"
                        >
                          <i class="fa fa-comment"></i>
                          {{ sponsor.onboarding_sponsor_logs_count }}
                        </router-link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Search Modal -->
    <div
      class="modal fade"
      id="filterModal"
      role="dialog"
      aria-labelledby="filterModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="filterModalLabel">Search Options</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="dateSearch">Filter Onboarding Sponsors</label>
                  <select class="form-control" v-model="filter">
                    <option value="active">Active</option>
                    <option value="closed">Closed</option>
                    <option value="with_elderly">With Beneficiary</option>
                    <option value="without_elderly">Without Beneficiary</option>
                    <option value="with_log">With Logs</option>
                    <option value="with_elderly_remark">
                      With Beneficiary & Logs
                    </option>
                    <option value="without_elderly_remark">
                      Without Beneficiary & with Logs
                    </option>
                    <option value="with_elderly_no_remark">
                      With Beneficiary & without Logs
                    </option>
                    <option value="without_elderly_without_remark">
                      Without Beneficiary & without Logs
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">
              <i class="flaticon-cancel-12"></i> Discard
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="handleFilter()"
            >
              Filter
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select2 from "v-select2-component";

import { gerohttp } from "@/utils/gerocarehttp";
import { geroendpoints } from "@/utils/endpoints";

var moment = require("moment");

export default {
  name: "OnboardingSponsors",
  components: {
    Select2,
  },

  data() {
    return {
      loading: false,
      onboardingSponsors: [],
      filter: "",
    };
  },

  mounted() {
    this.fetchOnboarding();
  },

  methods: {
    initTable() {
      var dt = $("#ONBOARDING_SPONSORS").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        order: [[4, "desc"]],
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
      dt.columns([9]).visible(false);
    },

    fetchOnboarding() {
      this.loading = true;
      let onboardingUrl =
        geroendpoints.FETCH_ONBOARDING_SPONSORS + `?filter=${this.filter}`;

      gerohttp
        .get(onboardingUrl)
        .then((response) => {
          this.onboardingSponsors = response;
          this.loading = false;
          setTimeout(() => {
            this.initTable();
          }, 1000);
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    handleFilter() {
      this.fetchOnboarding();
      $("#filterModal").modal("hide");
    },
  },
};
</script>

<style>
.select2-container {
  width: 60% !important;
}
.new-control {
  font-weight: 400;
  font-size: 15px;
}
</style>
